import { Card, Grid, Icon, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Customize_DataTable from '../../../common/Components/Cutomize_Datatable';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMaterialUIController } from "context";
import DirectionsIcon from '@mui/icons-material/Directions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { handleDateTime } from "lib/Utils";
import { deleteCodeList, getCodeList } from "../action";
import CreateCodeList from "../modals/CreateCodeList";
import { setCurrentFileName } from "main/FileDefination/reducer";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Details from "./Details";
import { handleColor } from "lib/Utils";

export default function Codelist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const accessToken = useSelector(store => store.common.access_token)
  const codeList = useSelector(store => store.codelist.codeList)


  const [open, setOpen] = useState(false)
  const [open_edit_rule, setopen_edit_rule] = useState(false)
  const [openDeleteModal, setopenDeleteModal] = useState(false)
  const [currentCodelist, setcurrentCodelist] = useState({})
  const [openFilterDialog, setopenFilterDialog] = useState(false);

  const [status, setstatus] = useState(null);
  const [codeListName, setcodeListName] = useState("");
  const [codeListDesc, setcodeListDesc] = useState("");

  const [order_by, setorder_by] = useState('')
  const [order_type, setorder_type] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (accessToken) {
      dispatch(getCodeList(accessToken))
    }
  }, [accessToken])

  useEffect(() => {
    if (isSubmitted) {
      onApplyFilter();
      setIsSubmitted(false); // Reset the flag after calling onApplyFilter
    }
  }, [isSubmitted])

  const onApplyFilter = (page = 1, export_status = false) => {

    var body = {
      'page': page,
      "page_size": 10
    }
    if (order_by) {
      body['order_by'] = order_by
    }
    if (order_type) {
      body["order_type"] = order_type
    }

    dispatch(getCodeList(accessToken, body))
    setopenFilterDialog(false);
  }

  return <DashboardLayout>
    <DashboardNavbar />

    <Details open={open} handleClose={() => { setOpen(false); }} />

    <MDBox mt={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor={sidenavColor}
              borderRadius="lg"
              coloredShadow={sidenavColor}
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <MDTypography fontSize={14} color="white">
                Extraction
              </MDTypography>

              {/* {codeList.count ? <Tooltip title="Export" ><DownloadForOfflineIcon fontSize='medium' color='white' style={{ cursor: "pointer" }} onClick={() => { onApplyFilter(1, true,) }} /></Tooltip> : ""} */}

            </MDBox>
            <MDBox p={1}>
              <Customize_DataTable
                count={codeList.count}
                rows={codeList.results}
                onPageChange={(page) => { onApplyFilter(page) }}
                handleSort={true}
                onHandleSort={(e) => { setorder_by(e.field); setorder_type(e.sort); setIsSubmitted(true); }}
                columns={[
                  // { field: "id", headerName: "ID", width: 90 },
                  {
                    field: "action", headerName: "Actions", width: 120, renderCell: (row) => <Typography>
                      <Link to={`/document/${row.row.id}`}>
                        <Tooltip title="Go To Library"  ><RemoveRedEyeIcon fontSize="small" style={{ marginLeft: 8, cursor: "pointer" }} /></Tooltip>
                      </Link>
                    </Typography>
                  },

                  { field: "document_type",  renderCell: (row) => <Typography fontSize={13} fontWeight={'light'}>{row.row.document_type == 'BL' ? "BILL OF LADING" : row.row.document_type}</Typography>, headerName: "Document Type", width: 180 },
                  { field: "file_name", headerName: "Document Name", width: 500 },
                  { field: "uploaded_at", renderCell: (row) => <Typography fontSize={13} fontWeight={'light'}>{handleDateTime(row.row.uploaded_at)}</Typography>, headerName: "Received On (UTC)", width: 200 },

                ]}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>


    <Tooltip title="Upload Document" placement="top" onClick={() => { setOpen(true); }} >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor={handleColor(sidenavColor)}
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="6.2rem"
        zIndex={99}
        color="white"
        sx={{ cursor: "pointer" }}
      >
        <Icon fontSize="medium" color="inherit">
          add
        </Icon>
      </MDBox>
    </Tooltip>

  </DashboardLayout>;
}
