import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_DATA } from 'lib/Constants'

export const codelist = createSlice({
    name: 'codelist',
    initialState: {
        codeList: DEFAULT_DATA,
        documentDetails: {},
        documentExtractedData: [],
        fileDetails: {},
    },
    reducers: {
        setCodeList: (state, action) => {
            state.codeList = action.payload
        },
        setDocumentDetails: (state, action) => {
            state.documentDetails = action.payload
        },
        setDocumentExtractedData: (state, action) => {
            state.documentExtractedData = action.payload
        },
        setFileDetails: (state, action) => {
            state.fileDetails = action.payload
        },
    },
})

export const {
    setCodeList, setDocumentDetails, setDocumentExtractedData, setFileDetails
} = codelist.actions

export default codelist.reducer