import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Switch, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";
import { NOTIFICATION_COLOUR } from "lib/Constants";
import { validator } from "lib/Utils";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onSetNotification } from "redux/action";
import { onCreateCodelist, onCreateRuleSetup, onUpdateProfileSetup, updateUser } from "../action";
import { useMaterialUIController } from "context";
import CloseIcon from '@mui/icons-material/Close';
import { FileUpload } from "primereact/fileupload";
import { document_type } from "lib/Constants";
import { handleImage } from "lib/Utils";
import { Tag } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setLoading } from "redux/reducer";

export default function Details({ open, handleClose }) {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;

    const fileUploadRef = useRef(null);

    const accessToken = useSelector(store => store.common.access_token)
    const common_color = useSelector(store => store.common.common_color)

    const [file, setfile] = useState(null);
    const [documentType, setdocumentType] = useState("");

    const [key_certificate_name, setkey_certificate_name] = useState("")
    const [totalSize, setTotalSize] = useState(0);

    const on_create_codeList = () => {
        if (file && documentType) {
            const formdata = new FormData();
            dispatch(setLoading(true))
            formdata.append("file", file)
            formdata.append("document_type", documentType)

            onCreateCodelist(accessToken, formdata,
                (success) => {
                    console.log(success)
                    dispatch(onSetNotification(NOTIFICATION_COLOUR.SUCCESS, "File Uploaded Successfully", true))
                    dispatch(setLoading(false))
                    window.location.reload();
                    onClose();
                },
                (error) => {
                    console.log(error)
                    dispatch(setLoading(false))

                    dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))

                });
        }
        else {
            dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, "Please fill The required fields.", true))
        }
    }

    const onClose = () => {

        setdocumentType("");
        setfile(null);
        handleClose();
    }


    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: 7.5 }}>
                <div style={{ display: 'flex', flexDirection: "row" }}>
                    {chooseButton}
                    {cancelButton}
                </div>
            </div>
        );
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            },
        },
    };

    const itemTemplate = (file, props) => {

        return (
            <div style={{
                backgroundColor: 'transparent',
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBlock: 10, marginInline: 10, width: "100%"
            }}>
                <div>
                    {<MDTypography fontSize={13} fontWeight="light" style={{ color: common_color.TEXT_LIGHT_COLOR }} className='me-2 ms-2'>{file.name}</MDTypography>}
                </div>
                {/* <div className='gap-3' style={{ display: "flex", flexDirection: "row", alignItems: "center", color: common_color.TEXT_COLOR }}>
                    <Tag value={props.formatSize} severity="warning" className="px-3 py-1" />
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-pc-section="buttonicon"><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor" onClick={() => onTemplateRemove(file, props.onRemove)}></path></svg>
                </div> */}
            </div>
        );
    };

    return <React.Fragment>
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth="md"
        >
            <Paper style={{ backgroundColor: common_color.BACKGROUND_COLOR, color: common_color.TEXT_COLOR }}>
                <DialogTitle >
                    <MDBox
                        mx={0}
                        mt={0}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor={sidenavColor}
                        borderRadius="lg"
                        coloredShadow={sidenavColor}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                    >
                        <MDTypography fontSize={13} color="white">
                            Upload Document
                        </MDTypography>

                        <Tooltip title="Close" >
                            <CloseIcon fontSize='medium' color='white' style={{ cursor: "pointer" }} onClick={onClose} />
                        </Tooltip>

                    </MDBox>
                </DialogTitle>
                <DialogContent>

                    <MDBox px={1}>

                        <MDBox component="form" role="form">

                            <MDBox>
                                <Row>
                                    <Col md="12" className="my-3">

                                        <MDBox>
                                            <FormControl fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel required={true} id="demo-simple-select-standard-label">{'Document Type'}
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={documentType}
                                                    onChange={(e) => { setdocumentType(e.target.value) }}
                                                    // onClick={onClick}
                                                    label={'Document Type'}
                                                    style={{ color: common_color.TEXT_COLOR, height: "44px", paddingRight: "0.75rem" }}
                                                    displayEmpty
                                                    inputProps={{ IconComponent: () => <ArrowDropDownIcon /> }}
                                                    MenuProps={MenuProps}
                                                >
                                                    {
                                                        document_type && document_type.length > 0 && document_type.map((item, index) => {
                                                            return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </MDBox>
                                    </Col>
                                    <Col md="12" className="mb-3">
                                        <FileUpload
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            name="demo[]" url={'/api/upload'}
                                            accept=""
                                            multiple={false}
                                            maxFileSize={1000000}
                                            onSelect={(e) => { setfile(e.files[0]); }}
                                            emptyTemplate={<MDTypography fontSize={13} fontWeight="light" style={{ backgroundColor: 'transparent', color: common_color.TEXT_LIGHT_COLOR, padding: " 7px 12px" }} >{`Drag and drop your documents here. *`}</MDTypography>}
                                        />
                                    </Col>
                                </Row>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </DialogContent>

                <DialogActions>
                    <MDButton onClick={onClose} style={{ marginRight: 10 }} >
                        Cancel
                    </MDButton>
                    <MDButton style={{ width: 90 }} onClick={on_create_codeList} variant="gradient" color={sidenavColor} fullWidth>
                        Create
                    </MDButton>
                </DialogActions>
            </Paper>
        </Dialog>
    </React.Fragment>;
}
