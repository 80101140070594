import { Card, Grid, Icon, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import { getDecumentDetails, getDocumentExtractedData, getFileDetails } from "../action";
import { DataGrid } from "@mui/x-data-grid";
import PandaLoader from "common/loaders/PandaLoader";
import { useResizeObserver } from '@wojtekmaj/react-hooks';


import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// import PaginationButton from './PaginationButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/legacy/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};
const resizeObserverOptions = {};

const maxWidth = 500;

export default function DocumentDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;

    const common_color = useSelector((store) => store.common.common_color)
    const accessToken = useSelector(store => store.common.access_token)
    const fileDetails = useSelector(store => store.codelist.fileDetails)
    const documentDetails = useSelector(store => store.codelist.documentDetails)
    const documentExtractedData = useSelector(store => store.codelist.documentExtractedData)

    const [numPages, setNumPages] = useState(null)
    const [chatbotReply, setchatbotReply] = useState("")
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();
    const [zoom, setZoom] = useState(1);
    const [pdfWidth, setpdfWidth] = useState("50vw");



    useEffect(() => {
        if (accessToken) {
            dispatch(getDecumentDetails(accessToken, params.id))
            dispatch(getDocumentExtractedData(accessToken, params.id))
            dispatch(getFileDetails(accessToken, params.id))
        }
    }, [accessToken])


    const dynamicBackgroundStyle = {
        '--dynamic-background-color': common_color.BACKGROUND_COLOR,
    };


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    const onResize = useCallback(
        (entries) => {
            const [entry] = entries;

            if (entry) {
                setContainerWidth(entry.contentRect.width);
            }
        },
        []
    );
    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom + 0.1);
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    console.log("`data:application/pdf;base64,${fileDetails.file_bytes}`", documentDetails)

    return <DashboardLayout>
        <DashboardNavbar currentHeaderTitle={documentDetails.file_name} />

        <MDBox mt={5}>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={1}
                            px={2}
                            variant="gradient"
                            bgColor={sidenavColor}
                            borderRadius="lg"
                            coloredShadow={sidenavColor}
                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                        >
                            <MDTypography fontSize={14} color="white">
                                Document Details
                            </MDTypography>
                        </MDBox>
                        <MDBox p={1}>

                            {(documentExtractedData && documentExtractedData.length > 0) ?
                                <DataGrid


                                    rows={documentExtractedData ?? []}
                                    columns={
                                        [
                                            { field: "key", renderCell: (row) => <Typography fontSize={13} fontWeight={'light'}>{row.row.key}</Typography>, headerName: "Key", width: 300 },
                                            { field: "value", headerName: "Value", width: 400 }

                                        ]
                                    }
                                    density="compact"
                                    // pageSizeOptions={[10, 25, 50]}
                                    disableRowSelectionOnClick={true}
                                    disableColumnMenu={true}
                                    style={{ color: common_color.TEXT_COLOR, borderColor: "transparent", paddingInline: 15, ...dynamicBackgroundStyle, fontSize: 13, paddingTop: 5, maxHeight: "80vh" }}
                                    hideFooter

                                />
                                :
                                <div style={{ paddingBlock: 10 }}>
                                    <PandaLoader />
                                    <MDTypography fontSize={13} textAlign="center" mt={1} >
                                        No Data Found
                                    </MDTypography>
                                </div>
                            }


                        </MDBox>


                    </Card>
                </Grid>


                <Grid item xs={6}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={1}
                            px={2}
                            variant="gradient"
                            bgColor={sidenavColor}
                            borderRadius="lg"
                            coloredShadow={sidenavColor}
                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                        >
                            <MDTypography fontSize={14} color="white">
                                Document
                            </MDTypography>
                        </MDBox>
                        <MDBox p={1} style={{ height: "82vh" }}>

                            {documentDetails.file_bytes && <iframe
                                id="inlineFrameExample"
                                title="File"
                                width="100%"
                                height="100%"
                                // src={'https://research.google.com/pubs/archive/44678.pdf'}
                                src={`data:application/pdf;base64,${documentDetails.file_bytes}`}
                            >
                            </iframe>}





                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>


    </DashboardLayout>;
}
