import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, TablePagination } from "@mui/material";
import { DataTable } from 'primereact/datatable';
import { useRef } from "react";
import { useState } from "react";
import { Column } from "primereact/column";
import PandaLoader from "common/loaders/PandaLoader";
import MDTypography from "components/MDTypography";
import { PAGE_SIZE } from "lib/Constants";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";

export default function DataGridDemo({ rows, columns, count, onPageChange, checkboxSelection, reorderableRows, handleSort, onHandleSort, disableColumnMenu }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;

    const common_color = useSelector((store) => store.common.common_color)

    const dynamicStyle = {
        '--dynamic-color': common_color.TEXT_COLOR,
    };

    const dynamicBackgroundStyle = {
        '--dynamic-background-color': common_color.BACKGROUND_COLOR,
    };

    return (
        <MDBox sx={{ width: "100%", backgroundColor: "transparent" }}
        //  style={dynamicBackgroundStyle}
        >

            {(rows && rows.length > 0) ?
                <DataGrid

                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_on', sort: 'desc' }],
                        },
                    }}


                    rows={rows ?? []}
                    columns={columns ?? []}
                    onSortModelChange={(e) => { handleSort && e.length > 0 && onHandleSort(e[0]) }}
                    density="compact"
                    pageSizeOptions={[10, 25, 50]}
                    checkboxSelection={checkboxSelection ?? false}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={disableColumnMenu == false ? false : true}
                    style={{ color: common_color.TEXT_COLOR, borderColor: "transparent", paddingInline: 15, ...dynamicBackgroundStyle, fontSize: 13, paddingTop: 5 }}
                    hideFooter

                />
                :
                <div style={{ paddingBlock: 10 }}>
                    <PandaLoader />
                    <MDTypography fontSize={13} textAlign="center" mt={1} >
                        No Data Found
                    </MDTypography>
                </div>
            }


            <Pagination count={Math.ceil(count / PAGE_SIZE)} color={sidenavColor} showFirstButton showLastButton
                onChange={(e, page) => { onPageChange(page) }}
                style={{ ...dynamicStyle, height: 50, display: "flex", alignItems: "center", justifyContent: "center" }}
            />
            {/* <TablePagination
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </MDBox>
    );
}
