import { NOTIFICATION_COLOUR } from "lib/Constants"
import { RESPONSE_TYPE } from "lib/Constants"
import { REQUEST_METHOD } from "lib/Constants"
import { onSetNotification } from "redux/action"
import { setLoading } from "redux/reducer"
import { setCodeList, setCodeListLibrary, setDocumentDetails, setDocumentExtractedData, setFileDetails } from "./reducer"
import { fetchApi } from "lib/Utils"
import { PAGE_SIZE } from "lib/Constants"



export const getCodeList = (accessToken, body = { "page": 1, "page_size": PAGE_SIZE }) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/document/list?${new URLSearchParams(body)}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch(setCodeList(successResponse))

                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }
        )
    }
}

export const getDocumentExtractedData = (accessToken, id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/document/extracted_data/${id}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch(setDocumentExtractedData(successResponse))
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }
        )
    }
}


export const getFileDetails = (accessToken, id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/document/file/${id}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch(setFileDetails(successResponse))
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }
        )
    }
}

export const getDecumentDetails = (accessToken, id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/document/file/${id}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch(setDocumentDetails(successResponse))

                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }
        )
    }
}

export const getCodeListLibrary = (accessToken, body = { "page": 1, "page_size": PAGE_SIZE }) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/list_library/filter/list`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                if (body.export == true) {
                    dispatch(onSetNotification(NOTIFICATION_COLOUR.SUCCESS, "Your document will be downloaded shortly!", true));

                    let blobResponse = URL.createObjectURL(successResponse);
                    const downloadLink = document.createElement("a");
                    downloadLink.download = `${new Date().getTime()}.xls`;
                    downloadLink.href = blobResponse;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                }
                else {
                    // dispatch(setCodeListLibrary(successResponse))
                }
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, body, true, body.export == true ? RESPONSE_TYPE.BLOB : RESPONSE_TYPE.JSON
        )
    }
}

export const deleteCodeList = (accessToken, id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/code_list/${id}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, "Codelist Deleted Successfully", true))
                dispatch(getCodeList(accessToken));
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, null, true, RESPONSE_TYPE.NULL
        )
    }
}

export const deleteCodeListLibrary = (accessToken, id, code_id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/list_library/${id}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, "Codelist Library Deleted Successfully", true))
                dispatch(getCodeListLibrary(accessToken, { "page": 1, "page_size": PAGE_SIZE, "code_list_id": code_id }));
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, null, true, RESPONSE_TYPE.NULL
        )
    }
}

export const onUpdateCodelist = (accessToken, body, id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/code_list/${id}`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch(onSetNotification(NOTIFICATION_COLOUR.SUCCESS, "Profile Updated Successfully", true))
                dispatch(getCodeList(accessToken));
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, body
        )
    }
}

export const onEditCodelistLibrary = (accessToken, body, id, code_id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/list_library/${id}`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch(onSetNotification(NOTIFICATION_COLOUR.SUCCESS, "Codelist Updated Successfully", true))
                dispatch(getCodeListLibrary(accessToken, { "page": 1, "page_size": PAGE_SIZE, "code_list_id": code_id }));
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, body
        )
    }
}

export const onCreateCodelist = (accessToken, body, successResponse, errorResponse) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
        'method': "POST",
        'headers': myHeaders,
        'body': body,
        'redirect': "follow",
    };

    fetchApi(
        `/document/upload`,
        accessToken,
        REQUEST_METHOD.POST,
        (success) => {
            successResponse(success);
        },
        (error) => {
            errorResponse(error);
        }, body, true, RESPONSE_TYPE.JSON, requestOptions
    )
}

export const onCreateCodelistLibrary = (accessToken, body, code_id) => {
    return function (dispatch) {
        dispatch(setLoading(true))
        fetchApi(
            `/edi/v1/conversion/list_library`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(onSetNotification(NOTIFICATION_COLOUR.SUCCESS, "Codelist Library Created Successfully", true))
                dispatch(getCodeListLibrary(accessToken, { "page": 1, "page_size": PAGE_SIZE, "code_list_id": code_id }));
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(setLoading(false))
                dispatch(onSetNotification(NOTIFICATION_COLOUR.DANGER, error, true))
            }, body
        )
    }
}