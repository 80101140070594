import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './reducer';
import authenticationReducer from 'main/authentication/sign-in/reducer';
import filedefinationReducer from 'main/FileDefination/reducer';
import codelistReducer from 'main/Codelist/reducer';

export default configureStore({
  reducer: {
    authentication: authenticationReducer,
    common: commonReducer,
    filedefination: filedefinationReducer,
    codelist: codelistReducer,
  },
})

// const rootReducer = combineReducers({
//   reducer: {
//     authentication: authenticationReducer,
//     common: commonReducer,
//     master: masterReducer,
//     tradingpartner: tradingpartnerReducer,
//     connectivity: connectivityReducer,
//     filedefination: filedefinationReducer,
//   },
// })


// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = createStore(persistedReducer);
// export const persistor = persistStore(store);