import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_DATA } from 'lib/Constants'

export const filedefination = createSlice({
    name: 'filedefination',
    initialState: {
        fileList: DEFAULT_DATA,
        standardFileProfileList: DEFAULT_DATA,
        fileTagList: DEFAULT_DATA,
        currentFileName: ""
    },
    reducers: {
        setFileList: (state, action) => {
            state.fileList = action.payload
        },
        setStandardFileProfileListt: (state, action) => {
            state.standardFileProfileList = action.payload
        },
        setFileTagList: (state, action) => {
            state.fileTagList = action.payload
        },
        setCurrentFileName: (state, action) => {
            state.currentFileName = action.payload
        },
    },
})

export const {
    setFileList, setFileTagList, setCurrentFileName, setStandardFileProfileListt
} = filedefination.actions

export default filedefination.reducer