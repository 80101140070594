// export const BASEURL = "http://192.168.0.43:9980";  //Local API
export const BASEURL = "https://docextraction.azurewebsites.net";      //Dev API
// export const BASEURL = "https://ediverseapi.cozentus.com";   //Prod API


export const REQUEST_METHOD = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE"
}

export const PAGE_SIZE = 10;

export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500
}

export const ITEM_PER_PAGE_LIST = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_READ_DATA = "False";
export const DEFAULT_DATA = {
    count: 0,
    next: null,
    previous: null,
    results: []
}

export const NOTIFICATION_COLOUR = {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    DANGER: 'error',
    PRIMARY: "primary",
    SECONDARY: "secondary",
    LIGHT: "light",
    DARK: "dark",
    ERROR: "error"
}

export const RESPONSE_TYPE = {
    JSON: 'json',
    NULL: null,
    BLOB: 'blob'
}

export const TIME_ZONE = [
    {
        value: 'Asia/Kolkata'
    }
]

export const PROTOCOLS = [
    { label: "API", value: "API" },
    // { label: "ESB", value: "ESB" },
    { label: "AS2", value: "AS2" },
    { label: "FSA", value: "FSA" },
    { label: "FTP", value: "FTP" },
    { label: "SFTP", value: "SFTP" },
    { label: "SMTP", value: "SMTP" }
]

export const FILE_FORMATS = [
    { label: "X12", value: "X12" },
    { label: "EDIFACT", value: "EDIFACT" },
    { label: "JSON", value: "JSON" },
    { label: "XML", value: "XML" },
    { label: "CSV", value: "CSV" }
]

export const CONVERSION_TYPES = [
    // { label: "EDI-TO-JSON", value: "EDI-TO-JSON" },
    // { label: "EDI-TO-RULE-JSON", value: "EDI-TO-RULE-JSON" },
    { label: "EDI-TO-EDI", value: "EDI-TO-EDI" },
    { label: "EDI-TO-NONEDI", value: "EDI-TO-NONEDI" },
    { label: "NONEDI-TO-EDI", value: "NONEDI-TO-EDI" },
    { label: "NONEDI-TO-NONEDI", value: "NONEDI-TO-NONEDI" },
]


export function updateFormattedDateTime() {
    const daysOfWeek = [
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const now = new Date();
    const dayOfWeek = daysOfWeek[now.getUTCDay()];
    const month = months[now.getUTCMonth()];
    const dayOfMonth = now.getUTCDate();
    const year = now.getUTCFullYear();
    const hours = now.getUTCHours().toString().padStart(2, '0');
    const minutes = now.getUTCMinutes().toString().padStart(2, '0');
    const seconds = now.getUTCSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${dayOfWeek}, ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime
}

export const listItems = [
    {
        name: "XML", icon: "bi bi-filetype-xml"
    },
    {
        name: "CSV", icon: "bi bi-filetype-csv"
    },
    {
        name: "JSON", icon: "bi bi-filetype-json"
    }
]

export const CIPHER_CHOICES = [
    { label: "RSA with 128-bit RC4 with SHA1", value: "RSA with 128-bit RC4 with SHA1" },
    { label: "RSA with 128-bit AES with SHA1", value: "RSA with 128-bit AES with SHA1" },
    { label: "RSA with 256-bit AES with SHA1", value: "RSA with 256-bit AES with SHA1" },
    { label: "RSA with 3DES with SHA1 ", value: "RSA with 3DES with SHA1 " },
    { label: "RSA with 128-bit RC4 with MD5", value: "RSA with 128-bit RC4 with MD5" },
    { label: "RSA with DES with SHA1", value: "RSA with DES with SHA1" }
]

export const SIGNING_CHOICES = [
    { label: "SHA1", value: "SHA1" },
    { label: "SHA256", value: "SHA256" }
]

export const ENCRYPTION_CHOICES = [
    { label: "Triple DES 168 CBC with PKCS5 padding", value: "Triple DES 168 CBC with PKCS5 padding" },
    { label: "56-bit DES CBC with PKCS5 padding", value: "56-bit DES CBC with PKCS5 padding" },
    { label: "128-bit RC2 CBC with PKCS5 padding", value: "128-bit RC2 CBC with PKCS5 padding" },
    { label: "40-bit RC2 CBC with PKCS5 padding", value: "40-bit RC2 CBC with PKCS5 padding" }
]

export const AUTH = [
    { label: 'ApiKey', value: 'ApiKey' },
    { label: 'Bearer', value: 'Bearer' },
    { label: 'Common', value: 'Common' }
]

export const CONTROLLING_AGENCY = [
    "AA",
    "AB",
    "AC",
    "AD",
    "AE",
    "AF",
    "AG",
    "AH",
    "AI",
    "CC",
    "CE",
    "EC",
    "ED",
    "EE",
    "EN",
    "ER",
    "EU",
    "EW",
    "EX",
    "IA",
    "KE",
    "LI",
    "OD",
    "RI",
    "RT",
    "UN",]

export const RELEASE_NUM = [
    "1",
    "2",
    "902",
    "911",
    "912",
    "921",
    "932",
    "00A",
    "00B",
    "01A",
    "01B",
    "01C",
    "02A",
    "02B",
    "93A",
    "94A",
    "94B",
    "95A",
    "95B",
    "96A",
    "96B",
    "97A",
    "97B",
    "98A",
    "98B",
    "99A",
    "99B"

]

export const SYNTAX_ID = [
    "1",
    "2",
    "4",
    "88",
    "89",
    "90",
    "D",
    "S"

]
export const SYNTAX_VERSION = [
    "UNOA",
    "UNOB",
    "UNOC",
    "UNOD",
    "UNOE",
    "UNOF",
    "UNOG",
    "UNOH",
    "UNOI",
    "UNOJ",
    "UNOK",
    "UNOX",
    "UNOY"
]

export const document_type = [
    { label: "BILL OF LADING", value: "BL" },
    { label: "PACKAGING LIST", value: "PACKAGING LIST" },
    { label: "COMMERCIAL INVOICE", value: "COMMERCIAL INVOICE" },
    { label: "AIR WAYBILL", value: "AIR WAYBILL" }
]