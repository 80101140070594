/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMaterialUIController } from "context";
import { setCurrentFileName } from "main/FileDefination/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const dispatch = useDispatch();

  const currentFileName = useSelector(store => store.filedefination.currentFileName)



  useEffect(() => {
    if (currentFileName) {
      localStorage.setItem("currentFileName", currentFileName);
    }
    else {
      const serializedState = localStorage.getItem("currentFileName")
      dispatch(setCurrentFileName(serializedState))
    }
  }, [currentFileName])


  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]), display: "flex", flexDirection: "row", alignItems: "center", fontSize: 14,
          },
        }}
        separator={<NavigateNextIcon fontSize="medium" />}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0, fontSize: 14, }}
          >
            <Icon fontSize="medium">{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el, index) => {
          if (routes.length - 1 !== index || routes.length == 1) {
            return <Link to={`/${el}`} key={el}>
              <MDTypography
                component="span"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
                color={sidenavColor}
                opacity={1}
                sx={{ lineHeight: 0, fontSize: 14, }}

              >
                {el.replace(/-/g, " ")}
              </MDTypography>
            </Link>
          }
          else {
            return <MDTypography fontSize={13} key={index}
              component="span"
              variant="h6"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0, fontSize: 14, }}
            >
              {el.replace(/-/g, " ")}
            </MDTypography>
          }

        })}
        <MDTypography
          variant="h6"
          fontWeight="regular"
          textTransform={routes.length == 0 && "capitalize"}
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0, fontSize: 14, }}
        >
          {title.replace(/-/g, " ")}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
