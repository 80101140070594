import { BASEURL, REQUEST_METHOD, RESPONSE_CODE, RESPONSE_TYPE, DEFAULT_DATA, TIME_ZONE } from './Constants';

export function getRequestHeader(accessToken) {
    let headers = {
        "Content-Type": "application/json",
        "accept": "application/json"
    };
    if (accessToken !== null) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return headers;
}

export const fetchApi = (endPoint, accessToken, method, successCallBack, errorCallBack, body = null, setBaseUrl = true, responseType = RESPONSE_TYPE.JSON, requestOptions = null) => {
    let options = {
        method: method,
        headers: getRequestHeader(accessToken)
    };

    let requestUrl = endPoint;
    if (setBaseUrl) {
        requestUrl = `${BASEURL}${endPoint}`;
    }

    if (method === REQUEST_METHOD.GET && body !== null) {
        errorCallBack("GET request does not support body")
        return null
    } else if (method !== REQUEST_METHOD.GET) {
        options["body"] = JSON.stringify(body)
    }

    fetch(requestUrl, requestOptions ?? options)
        .then(response => {
            if (response.status >= 400) {
                return response
            } else {
                switch (responseType) {
                    case RESPONSE_TYPE.JSON:
                        return response.json();
                    case RESPONSE_TYPE.BLOB:
                        return response.blob();
                    case RESPONSE_TYPE.NULL:
                        return DEFAULT_DATA
                }
            }
        })
        .then(responseJson => {
            if (responseJson.type === 'cors') {
                apiErrorHandler(responseJson, errorCallBack, successCallBack)
            } else {
                successCallBack(responseJson)
            }
        }).catch(error => {
            console.log("`Something Went Wrong. error : ${error}`", `Something Went Wrong. error : ${error}`, requestUrl, options)
            errorCallBack(`Something Went Wrong. error : ${error}`)
        })
}

export function apiErrorHandler(response, errorCallBack, successCallBack) {
    switch (response.status) {
        case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
            return errorCallBack(`Something Went Wrong, please try again later.`);
        case RESPONSE_CODE.FORBIDDEN:
            errorCallBack(`You do not have permission to perform this action.`)
            break;
        case RESPONSE_CODE.UNAUTHORIZED:
            if (window.location.pathname == '/authentication/sign-in') {
                errorCallBack("Unauthorized Access")
            }
            else {
                errorCallBack("Token has been expired, Please login again.")
            }
            deleteUserDataFromStorage('loginData');
            window.location.reload();
            break;
        default:
            getApiErrorMessage(response, errorCallBack, successCallBack);
            break;
    }
}

export function getApiErrorMessage(response, errorCallBack, successCallBack) {
    (response.json()).then((data) => {
        if (typeof data === 'string')
            return errorCallBack(data)

        if (data.content_error) {
            return successCallBack(data)
        }
        if (Array.isArray(data))
            return errorCallBack(data)
        let key = Object.keys(data)
        key = key[0]
        if (typeof data[key] === 'string')
            return errorCallBack(data[key])

        if (data.length > 0) {
            data = data[0]
        }
        let keys = Object.keys(data)
        if (keys.length > 0) {
            keys = keys[0]
        }
        return errorCallBack(`${keys}:${data[keys][0]}`)
    })
}

export async function ConvertPageImageToBlob(accessToken, imageUrl) {
    let requestOptions = {
        method: REQUEST_METHOD.GET,
        headers: getRequestHeader(accessToken),
    };
    let response = await fetch(imageUrl, requestOptions)
    response = await response.blob()
    let blobResponse = await URL.createObjectURL(response);
    return blobResponse;
}

export function setUserDataToStorage(key, data, navigate) {
    localStorage.setItem(key, JSON.stringify(data));
    navigate('/dashboard');
}

export function getUserDataFromStorage(key) {
    try {
        const serializedState = localStorage.getItem(key)
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export function deleteUserDataFromStorage(key) {
    localStorage.removeItem(key);
}

export function csvToJSON(csv) {
    csv = csv.replace('\r', '');
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
            if (currentline[j] !== undefined && currentline[j] !== "") {
                obj[headers[j]] = currentline[j];
            }
        }
        if (Object.keys(obj).length > 0) {
            result.push(obj);
        }
    }
    return result;
}

export function readFile(file) {
    return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = x => resolve(fr.result);
        fr.readAsText(file);
    })
}

export function setTimezone(date, timeZone = TIME_ZONE[0].value) {
    var invdate = new Date(date.toLocaleString('en-US', {
        timeZone: timeZone
    })).toString();
    invdate = invdate.slice(0, invdate.indexOf('G'))
    return invdate;
}


export const custom_validator = (required, value, field_name, required_fields) => {
    var myObj = { status: false, helperText: "" }
    if (required_fields.includes(field_name)) {
        if (value !== "") {
            if (field_name == "first_name" || field_name == "last_name")
                myObj = /^[a-zA-Z ]*$/.test(value) ? { status: false, helperText: "" } : { status: true, helperText: "This field should contain alphabets only" }
            else if (field_name == "phone")
                myObj = /^(?:\+\d{1,3}[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value) ? { status: false, helperText: "" } : { status: true, helperText: "This is not a valid phone number" }
            else if (field_name == "email")
                myObj = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? { status: false, helperText: "" } : { status: true, helperText: "Mail should be in a valid format" }
            else if (field_name == "organization_name")
                myObj = /^[A-Za-z0-9 ]+$/.test(value) ? { status: false, helperText: "" } : { status: true, helperText: "This field should not contain special characters." }
        }

        else if (required) {
            return { status: true, helperText: "This is a required field" }
        }
    }
    return myObj
}


export function validator(type, checkValue) {
    try {
        switch (type) {
            case "alphabet":
                return /^[a-zA-Z ]*$/.test(checkValue);
            case "number":
                return /^\d+$/.test(checkValue);
            case "alphanumeric":
                return /^[A-Za-z0-9 ]+$/.test(checkValue);
            case "email":
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(checkValue);
            case "password":
                return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(checkValue);

            default:
                return true;
        }
    }
    catch (error) {
        console.log(error, type);
        return true;
    }
}


export const makeColumnArrayToObject = (my_arr = []) => {
    var updatedArr = []
    my_arr.length > 0 && my_arr.map((item, index) => {
        var obj = { name: item, code: index }
        updatedArr.push(obj)
    })
    return updatedArr
}

export const customizeDropdownData = (data, name = "name") => {
    const my_arr = []
    data && data.length > 0 && data.map((item, index) => {
        var obj = { name: item[name], code: item.id }
        my_arr.push(obj)
    })
    return my_arr
}

export const handleDateTime = (datetimeString) => {

    if (datetimeString) {
        const localDate = new Date(datetimeString);

        // Extract UTC components
        const year = localDate.getUTCFullYear();
        const month = String(localDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(localDate.getUTCDate()).padStart(2, '0');
        const hours = String(localDate.getUTCHours()).padStart(2, '0');
        const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(localDate.getUTCSeconds()).padStart(2, '0');

        // Format the date in YYYY-MM-DD HH:MM:SS format
        const utcDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return utcDateString;
    }

}

export const handleImage = (base64, name) => {
    if (name && name.includes("pdf")) {
        return <img title='Download' src={require("../assets/images/PDF_file_icon.png")} style={{ height: 37, width: 27, cursor: "pointer" }} onClick={() => {
            const downloadLink = document.createElement("a");

            downloadLink.href = base64;

            downloadLink.download = name;

            downloadLink.click();
        }} />
    }
    else if (name && name.includes("csv")) {
        return <img title='Download' src={require("../assets/images/csv_image.png")} style={{ height: 40, width: 30, cursor: "pointer" }}
            onClick={() => {
                const downloadLink = document.createElement("a");

                downloadLink.href = base64;

                downloadLink.download = name;

                downloadLink.click();
            }} />
    }
    else {
        return <img title='Download' src={base64} style={{ maxHeight: 40, maxWidth: 30, cursor: "pointer" }}
            onClick={() => {
                const downloadLink = document.createElement("a");

                downloadLink.href = base64;

                downloadLink.download = name;

                downloadLink.click();
            }} />
    }
    // else if(base64.includes(""))
}


export function formatByteSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const threshold = 1024;

    let value = bytes;
    let index = 0;

    while (value >= threshold && index < sizes.length - 1) {
        value /= threshold;
        index++;
    }

    return value && `${value.toFixed(1)} ${sizes[index]}`;
}

export const handleColor = (color) => {
    switch (color) {
        case "primary":
            return "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))";
        case "dark":
            return "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))";
        case "info":
            return "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))";
        case "success":
            return "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))";
        case "warning":
            return "linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0))";
        case "error":
            return "linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53))";
        default:
            return null;
    }
}

// Function to test string
export function isJSON(str) {
    const jsonRegex = /^[\],:{}\s]*$/
        .test(str.replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
    return jsonRegex;
}

export const handleFileFormats = (conversion_type, type) => {
    if (conversion_type == 'EDI-TO-EDI') {
        return [
            { label: "X12", value: "X12" },
            { label: "EDIFACT", value: "EDIFACT" }
        ]
    }

    else if (conversion_type == 'NONEDI-TO-NONEDI') {
        return [
            { label: "JSON", value: "JSON" },
            { label: "XML", value: "XML" },
            { label: "CSV", value: "CSV" }
        ]
    }
    else if (conversion_type == 'EDI-TO-NONEDI') {
        if (type == 'in') {
            return [
                { label: "X12", value: "X12" },
                { label: "EDIFACT", value: "EDIFACT" }
            ]
        }
        else {
            return [
                { label: "JSON", value: "JSON" },
                { label: "XML", value: "XML" },
                { label: "CSV", value: "CSV" }
            ]
        }
    }
    else if (conversion_type == 'NONEDI-TO-EDI') {
        if (type == 'in') {
            return [
                { label: "JSON", value: "JSON" },
                { label: "XML", value: "XML" },
                { label: "CSV", value: "CSV" }
            ]
        }
        else {
            return [
                { label: "X12", value: "X12" },
                { label: "EDIFACT", value: "EDIFACT" }
            ]
        }
    }
}